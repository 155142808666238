import { useEffect, useState } from "react";
import { useSmartObject } from "@eveworld/contexts";
import memoize from "memoizee";
import { SmartAssembly } from "@eveworld/types";
import { useLocation } from "react-router-dom";

import { useMUD } from "@/MUDContext";
import { IMarketState } from "./types";
import FullMarket from "./FullMarket";
import { useEffectOnce } from "@/utils/hooks/useEffectOnce";
import SingleItemMarket from "./SingleItemMarket";
import { Location as DeployableLocation } from "@/types/locations";
import { getSSUsInRange } from "./fetchers";
import { DEFAULT_RELATIVE_DISTANCE_IN_LY } from "@/constants";

const baseState: IMarketState = {
  locations: {},
  isLoading: true,
  itemOrders: null,
  itemsAvailable: [],
};

const Market = ({
  characterId,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
  showTradeAssetBalance = false,
}: {
  characterId: string;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
  showTradeAssetBalance?: boolean;
}) => {
  const { smartAssembly } = useSmartObject();
  const location = useLocation();
  const itemId = new URLSearchParams(location.search).get("itemId");
  const {
    systemCalls: {
      getItemsOnOrderAtSSU,
      getItemOrdersFromSSUs,
      getIsActiveSSUs,
      getItemIsOnOrderAtSSUs,
      getStructuresLocations,
    },
  } = useMUD();
  const [state, setState] = useState<IMarketState>(baseState);
  useEffect(() => {
    console.log("Market: itemId changed", itemId, smartAssembly);
    if (!itemId) return;
    if (!smartAssembly) {
      console.error("No smart assembly found");
    }
    // get the orders for the selected itemId
    memoizedItemsFetch().finally(() => {
      console.log("Items Available check done");
    });
  }, [itemId]);
  const memoizedItemsFetch = memoize(
    async () => {
      if (!smartAssembly || !smartAssembly.id) {
        console.error("No smart assembly found");
        return;
      }
      const itemsAvailable = await getItemsOnOrderAtSSU(
        BigInt(smartAssembly.id)
      );
      setState(
        (prevState: IMarketState) =>
          ({
            ...prevState,
            isLoading: false,
            itemsAvailable,
          }) as IMarketState
      );
    },
    { maxAge: 5000, promise: true }
  );
  useEffectOnce(() => {
    console.log("Items Available check");

    memoizedItemsFetch().finally(() => {
      console.log("Items Available check done");
    });
    const interval = setInterval(() => {
      memoizedItemsFetch();
    }, 5000);
    return () => clearInterval(interval);
  });

  useEffectOnce(() => {
    const getActiveSSUsInRange = async () => {
      console.time("getActiveSSUsInRange");
      if (
        smartAssembly === null ||
        !smartAssembly.solarSystem ||
        !smartAssembly.solarSystem.solarSystemId ||
        !itemId
      ) {
        console.error("TradeWindow: smartAssembly is null", {
          smartAssembly,
          solarSystem: smartAssembly?.solarSystem,
          id: smartAssembly?.solarSystem?.solarSystemId,
          itemId,
        });
        return;
      }
      const ssusInRange = await getSSUsInRange(
        parseInt(smartAssembly.solarSystem.solarSystemId),
        DEFAULT_RELATIVE_DISTANCE_IN_LY
      );
      console.log("ssusInRange", ssusInRange);
      const ssuIds: bigint[] = ssusInRange.map((ssu: SmartAssembly) =>
        BigInt(ssu.id)
      );
      // console.log("SSUs in range", ssusInRange);
      const activeSSUsInRange = await getIsActiveSSUs(ssuIds)
        .then((d) => {
          return ssuIds.reduce((acc, ssu, idx) => {
            if (d[idx]) {
              acc.push(ssu);
            }
            return acc;
          }, [] as bigint[]);
        })
        .catch((err) => {
          console.error("Error fetching active ssus", err);
          return [];
        });
      if (activeSSUsInRange.length === 0) {
        console.error("No active ssus in range");
        return;
      }
      const ssusWithItemOrders = await getItemIsOnOrderAtSSUs(
        activeSSUsInRange,
        BigInt(itemId as string)
      )
        .then((d) => {
          return activeSSUsInRange.reduce((acc, ssu, idx) => {
            if (d[idx]) {
              acc.push(ssu);
            }
            return acc;
          }, [] as bigint[]);
        })
        .catch((err) => {
          console.error("Error fetching ssus with item orders", err);
          return [];
        });
      if (ssusWithItemOrders.length === 0) {
        console.error("No ssus with item orders");
        return;
      }
      const itemOrders = await getItemOrdersFromSSUs(
        [...ssusWithItemOrders, BigInt(smartAssembly.id)],
        BigInt(itemId as string)
      )
        .then((d) => {
          return d.flat();
        })
        .catch((err) => {
          console.error("Error fetching item orders", err);
          return [];
        });
      console.timeEnd("getActiveSSUsInRange");
      const smartObjIds = Array.from(
        new Set([
          ...itemOrders.map((order) => order.smartObjectId),
          BigInt(smartAssembly?.id),
        ])
      );
      // console.log("itemOrders", itemOrders);
      getStructuresLocations(smartObjIds).then((locations) => {
        setState((prevState) => ({
          ...prevState,
          locations: locations.reduce(
            (acc, cur, idx) => {
              if (!cur || !smartObjIds[idx]) {
                return acc;
              }
              acc[smartObjIds[idx].toString()] = cur;
              return acc;
            },
            {} as Record<string, DeployableLocation>
          ),
        }));
      });
      setState((prevState) => ({ ...prevState, itemOrders }));
    };
    getActiveSSUsInRange();
    setInterval(() => {
      getActiveSSUsInRange();
    }, 1000 * 5);
  });
  console.log("LOAD: ", itemId, state.itemOrders);
  return (
    <div className="flex">
      {itemId && state.itemOrders !== null ? (
        <SingleItemMarket
          state={state}
          tradeAssetTicker={tradeAssetTicker}
          tradeAssetBalance={tradeAssetBalance}
          tradeAssetDecimals={tradeAssetDecimals}
        />
      ) : (
        <FullMarket state={state} />
      )}
    </div>
  );
};
export default Market;
