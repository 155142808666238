import React, { ReactNode } from "react";
import { SmartCharacter } from "@eveworld/types";
import { WalletClient } from "viem";
import { Header } from "@eveworld/ui-components";
import "@eveworld/ui-components/styles-ui.css";
interface EveLayoutProps {
  children: ReactNode;
  isCurrentChain: boolean;
  connected: boolean;
  handleDisconnect: () => void;
  walletClient: WalletClient | null;
  smartCharacter: SmartCharacter;
}

const EveLayout: React.FC<EveLayoutProps> = ({
  isCurrentChain,
  children,
  connected,
  handleDisconnect,
  walletClient,
  smartCharacter,
}) => {
  // is in the embedded dapp if the viewport is less than 968px
  const isEmbeddedDapp = window.innerWidth < 1068;
  const dappWidth = isEmbeddedDapp ? "560px" : "1800px";
  return (
    <div className="bg-crude-5 w-screen min-h-screen">
      <div
        className={`flex flex-col align-center ${isEmbeddedDapp ?? `max-w-[560px]`} mx-auto pb-6 min-h-screen h-full`}
        style={{ maxWidth: dappWidth }}
      >
        <Header
          isCurrentChain={isCurrentChain}
          connected={connected}
          handleDisconnect={handleDisconnect}
          walletClient={walletClient}
          smartCharacter={smartCharacter}
        />
        {children}
      </div>
    </div>
  );
};

export default EveLayout;
