export const DEFAULT_DECIMAL_PLACES_TO_DISPLAY = 6;
export const DEFAULT_TOKEN_DECIMALS = 18;

export const DEFAULT_BASIS_POINTS_DIVISOR = 1000;

export const DEFAULT_IPFS_GATEWAY = "https://ipfs.io/ipfs/";
export const DEFAULT_SOLAR_SYSTEMS_IPFS_CID =
  "QmeUDGxWUPtSt19dd5kNKNWQBbwjvLufuCF43WdiSzqVfZ";

export const DEFAULT_RELATIVE_DISTANCE_IN_LY = 500;
export const LIGHT_YEAR_IN_METERS = 9.46073047e15;