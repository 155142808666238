import { group } from "console";
import { Attribute, FullItemMetadata } from "./types";
import { ITradeWindowState } from "./tradeWindow.reducer";
import { converBigIntToDisplayNumber } from "@/utils/displayFormat";
import { convertFloatToBigInt } from "@/utils/decimals";
import {
  DEFAULT_BASIS_POINTS_DIVISOR,
  DEFAULT_DECIMAL_PLACES_TO_DISPLAY,
} from "@/constants";

interface GroupingAttributes {
  groupId: string | null;
  categoryId: string | null;
}

const findGroupingAttribute = (
  attributes: Attribute[]
): GroupingAttributes | undefined => {
  const attr: GroupingAttributes = attributes.reduce(
    (acc, cur) => {
      if (cur.trait_type === "groupName") {
        acc.groupId = cur.value as string;
      } else if (cur.trait_type === "categoryName") {
        acc.categoryId = cur.value as string;
      }
      return acc;
    },
    { groupId: null, categoryId: null } as GroupingAttributes
  );
  if (!attr.groupId || !attr.categoryId) {
    return undefined;
  }
  return attr;
};

/**
 * Filters items to groups
 * @param itemsMap - Map of items
 * @returns Map of groups
 */
export const filterItemsToGroups = (
  itemsMap: Map<bigint, FullItemMetadata>
): Map<string, Map<string, bigint[]>> => {
  const groups: Map<string, Map<string, bigint[]>> = new Map();
  // const itemTotalCount = itemsMap.size;
  // console.log("itemTotalCount", Array.from(itemsMap.values()));
  itemsMap.forEach((item, i) => {
    // console.log(i, "item", item);
    if (!item) {
      return;
    }
    const groupingAttribute = findGroupingAttribute(
      item.metadata?.metadata?.attributes
    );
    if (!groupingAttribute) {
      return;
    }
    if (!groups.has(groupingAttribute.categoryId as string)) {
      // if categoryId does not exist - create it and it's groupId map
      const groupId = new Map();
      groups.set(groupingAttribute.categoryId as string, groupId);
      groupId.set(groupingAttribute.groupId as string, [item.itemId]);
    } else if (
      !groups
        .get(groupingAttribute.categoryId as string)
        ?.has(groupingAttribute.groupId as string)
    ) {
      // if groupId does not exist - create it and it's item list
      groups
        .get(groupingAttribute.categoryId as string)
        ?.set(groupingAttribute.groupId as string, [item.itemId]);
    } else {
      groups
        .get(groupingAttribute.categoryId as string)
        ?.get(groupingAttribute.groupId as string)
        ?.push(item.itemId);
    }
  });
  return groups;
};

const validateInput = (
  state: ITradeWindowState
): { success: boolean; error: string | null } => {
  if (state.price.error) {
    return { success: false, error: state.price.error };
  }
  if (state.quantity.error) {
    return { success: false, error: state.quantity.error };
  }
  if (state.itemId === null) {
    return { success: false, error: "Item must be selected" };
  }
  if (state.quantity.value === BigInt(0)) {
    return { success: false, error: "Quantity must be greater than 0" };
  }
  if (state.price.value === BigInt(0)) {
    return { success: false, error: "Price must be greater than 0" };
  }
  return { success: true, error: null };
};

/**
 * Gets the adjusted unit price based on the fee basis points and the price
 * @param price - price of the item
 * @param decimals - decimal places
 * @param feeBasisPoints - fee basis points
 * @returns - adjusted unit price
 */
export const getAdjustedUnitPrice = (
  price: bigint,
  decimals: number,
  feeBasisPoints: bigint
) => {
  const divisor = DEFAULT_BASIS_POINTS_DIVISOR;
  // console.debug(
  //   "why is this not working",
  //   price,
  //   feeBasisPoints,
  //   divisor,
  //   " === ",
  //   (price * (BigInt(divisor) + feeBasisPoints)) / BigInt(divisor)
  // );
  return (price * (BigInt(divisor) + feeBasisPoints)) / BigInt(divisor);
};

export const adjustAndReturnUnitPrice = (
  price: bigint,
  decimals: number,
  feeBasisPoints: bigint
) => {
  const adjustmentPercentage =
    feeBasisPoints / BigInt(DEFAULT_BASIS_POINTS_DIVISOR);
  const currentPrice = parseFloat(converBigIntToDisplayNumber(price, decimals));
  // console.log(
  //   "currentPrice",
  //   currentPrice,
  //   "adjustmentPercentage",
  //   adjustmentPercentage,
  //   "price",
  //   price,
  //   "decimals",
  //   decimals,
  //   (currentPrice * (1 + adjustmentPercentage)).toFixed(
  //     DEFAULT_DECIMAL_PLACES_TO_DISPLAY
  //   )
  // );
  return price === 0n
    ? "0.0"
    : (currentPrice * (1 + parseInt(adjustmentPercentage.toString()))).toFixed(
        DEFAULT_DECIMAL_PLACES_TO_DISPLAY
      );
};

export const adjustAndCalculateTotalPrice = (
  price: bigint,
  quantity: bigint,
  decimals: number,
  adjustmentPercentage: number
) => {
  return price === 0n
    ? "0.0"
    : (
        parseFloat(converBigIntToDisplayNumber(quantity * price, decimals)) *
        (1 + adjustmentPercentage)
      ).toFixed(DEFAULT_DECIMAL_PLACES_TO_DISPLAY);
};
