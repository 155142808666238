import { ForceGraph3DInstance } from "3d-force-graph";
import { Font } from "three/examples/jsm/loaders/FontLoader";
import { CoordsWithMeta, CoordsWithMetaAndRelations } from "./types";

export interface IMapState {
  font: undefined | Font;
  nodes: any[];
  links: any[];
  type: string;
  scaleFactor?: number;
  mapDisplayType: MapDisplayType;
  nodesMap: { [key: string]: any };
  nodesMapById: { [key: string]: any };
  constellationMap: { [key: string]: number[] };
  regionMap: { [key: string]: number[] };
  activeAssembliesCountMap: { [key: string]: number };
  focusedNode: CoordsWithMetaAndRelations | undefined;
  destinationNode: CoordsWithMetaAndRelations | undefined;
  graph: ForceGraph3DInstance | null;
  isInitialized: boolean;
  isDataInitialized: boolean;
}

export const enum MapDisplayType {
  REGION = "region",
  NPC_STATION = "npc_station",
  ACTIVE_ASSEMBLIES_COUNT = "active_assemblies_count",
}

export const enum MapActions {
  SET_IS_INITIALIZED = "SET_IS_INITIALIZED",
  SET_INITIALIZED = "SET_INITIALIZED",
  SET_GRAPH = "SET_GRAPH",
  SET_FOCUSED_NODE = "SET_FOCUSED_NODE",
  SET_DESTINATION_NODE = "SET_DESTINATION_NODE",
  SET_MAP_DISPLAY_TYPE = "SET_MAP_DISPLAY_TYPE",
  SET_SCALE_FACTOR = "SET_SCALE_FACTOR",
  SET_ACTIVE_ASSEMBLIES_COUNT_MAP = "SET_ACTIVE_ASSEMBLIES_COUNT_MAP",
}

export const initialMapReducerState: IMapState = {
  font: undefined,
  nodes: [],
  links: [],
  type: "",
  scaleFactor: undefined,
  mapDisplayType: MapDisplayType.NPC_STATION,
  nodesMap: {},
  nodesMapById: {},
  constellationMap: {},
  regionMap: {},
  activeAssembliesCountMap: {},
  focusedNode: undefined,
  destinationNode: undefined,
  isInitialized: false,
  isDataInitialized: false,
  graph: null,
};

export const mapReducer = (
  state = initialMapReducerState,
  action: { type: MapActions; payload: any }
): IMapState => {
  switch (action.type) {
    case MapActions.SET_GRAPH:
      return {
        ...state,
        graph: action.payload,
      };
    case MapActions.SET_INITIALIZED:
      return {
        ...state,
        font: action.payload.font,
        nodes: action.payload.graphData.nodes,
        links: action.payload.graphData.links,
        type: action.payload.graphData.type,
        nodesMap: action.payload.graphData.nodesMap,
        nodesMapById: action.payload.graphData.nodesMapById,
        constellationMap: action.payload.graphData.constellationMap,
        regionMap: action.payload.graphData.regionMap,
        isDataInitialized: true,
      };
    case MapActions.SET_FOCUSED_NODE:
      return {
        ...state,
        focusedNode: action.payload,
      };
    case MapActions.SET_DESTINATION_NODE:
      return {
        ...state,
        destinationNode: action.payload,
      };

    case MapActions.SET_MAP_DISPLAY_TYPE:
      return {
        ...state,
        mapDisplayType: action.payload,
      };
    case MapActions.SET_SCALE_FACTOR:
      return {
        ...state,
        scaleFactor: action.payload,
      };
    case MapActions.SET_ACTIVE_ASSEMBLIES_COUNT_MAP:
      return {
        ...state,
        activeAssembliesCountMap: action.payload,
      };
    default:
      return state;
  }
};
