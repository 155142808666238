import memoize from "memoizee";

import { Coords, CoordsWithMeta, CoordsWithMetaAndRelations } from "./types";
import allSystems from "../../assets/formatted.json";
import npcStationLocations from "../../assets/npcStations.json";
import {
  distanceBetweenTwoPoints,
  getNumberCoordsAsBigInt,
  lightYearsBetweenTwo3DPoints,
} from "@/utils";
import { IMapState, MapDisplayType } from "./reducer";
import * as THREE from "three";
import { COLOR_THEME, mapDisplayTypeKey, SETTINGS } from "./constants";

const memoizedGetScaledFactor = memoize(() => {
  const coords: CoordsWithMetaAndRelations[] =
    allSystems as CoordsWithMetaAndRelations[];
  const locationCoordsToUse = coords.reduce<CoordsWithMetaAndRelations[]>(
    (acc, item, i) => {
      if (
        !(
          item.solarSystemId &&
          item.a_name &&
          item.locationX &&
          item.locationY &&
          item.locationZ
        )
      ) {
        return acc;
      }
      const { locationX, locationY, locationZ, fx, fy, fz, ...rest } = item;
      acc.push({
        ...rest,
        locationX,
        locationY,
        locationZ,
        x: locationX,
        y: locationY,
        z: locationZ,
      });
      return acc;
    },
    []
  );
  return getScaleValue(locationCoordsToUse);
}, { maxAge: 60 * 1000 });
export const getScaleFactor = () => {
  return memoizedGetScaledFactor();
}

const memoizedGetCoords = memoize(
  () => {
    console.time("getCoords");

    const coords: CoordsWithMetaAndRelations[] =
      allSystems as CoordsWithMetaAndRelations[];
    const locationCoordsToUse = coords.reduce<CoordsWithMetaAndRelations[]>(
      (acc, item, i) => {
        if (
          !(
            item.solarSystemId &&
            item.a_name &&
            item.locationX &&
            item.locationY &&
            item.locationZ
          )
        ) {
          return acc;
        }
        const { locationX, locationY, locationZ, fx, fy, fz, ...rest } = item;
        acc.push({
          ...rest,
          locationX,
          locationY,
          locationZ,
          x: locationX,
          y: locationY,
          z: locationZ,
        });
        return acc;
      },
      []
    );
    //   const locationCoordsScaled = scaleEachTo100(locationCoordsToUse);
    // filter out locations that don't have all the required fields
    // const middlePosition = centerGraph(locationCoordsToUse);
    // const shiftedCoords = shiftCoordinatesToCenter(
    //   locationCoordsToUse,
    //   middlePosition
    // );

    const scaledCoords = scaleCoordinatesToRange(locationCoordsToUse);
    // setInitialFocusedCoords(extractMiddleOfCoords(scaledCoords));
    const nodes = scaledCoords.map((location, idx) => {
      return {
        a_name: location.a_name,
        id: idx,
        solarSystemId: location.solarSystemId,
        constellationID: location.constellationID,
        regionID: location.regionID,
        name: location.a_name,
        relations: location.relations,
        relationsLength: location.relations.length,
        x: location.x,
        y: location.y,
        z: location.z,
        locationX: location.locationX,
        locationY: location.locationY,
        locationZ: location.locationZ,
      };
    });
    const linksMap = new Map<
      string,
      { source: number; /*name: string;*/ target: number }
    >();
    const mapOfNodes = nodes.reduce<Record<string, any>>(
      (acc, curr) => {
        if (curr.a_name === "M.699.MNI") {
          console.log("Node in reduce", curr);
        }
        acc.byId[curr.solarSystemId.toString()] = curr;
        acc.byName[curr.name.toLowerCase()] = curr;
        if (!acc.constellationMap[curr.constellationID]) {
          acc.constellationMap[curr.constellationID] = [];
        }
        acc.constellationMap[curr.constellationID].push(curr.id);
        if (!acc.regionMap[curr.regionID]) {
          acc.regionMap[curr.regionID] = [];
        }
        acc.regionMap[curr.regionID].push(curr.id);
        return acc;
      },
      { byId: {}, byName: {}, constellationMap: {}, regionMap: {} }
    );
    nodes.forEach((node) => {
      node.relations.forEach((rel) => {
        const targetNode = mapOfNodes.byId[rel.solarSystemId.toString()];
        if (!targetNode) {
          return;
        }
        const linkId = `${node.solarSystemId.toString()}-${targetNode.solarSystemId.toString()}`;
        const duplicateLinkId = `${targetNode.solarSystemId.toString()}-${node.solarSystemId.toString()}`;
        if (
          linksMap.has(linkId) ||
          linksMap.has(duplicateLinkId) ||
          node.solarSystemId === targetNode.solarSystemId
        ) {
          return;
        }
        if (!rel.distance) {
          console.error("No distance found for relation", rel);
          return;
        }
        linksMap.set(linkId, {
          source: node.id,
          target: targetNode.id,
          // name: `${node.name} - ${targetNode.name}: ${rel.distance.toString().slice(0, 6)}LY`,
        });
        return;
      });
    });
    const data = {
      nodes,
      links: Array.from(linksMap.values()),
      type: "NPC Gate",
      constellationMap: mapOfNodes.constellationMap,
      regionMap: mapOfNodes.regionMap,
      nodesMap: mapOfNodes.byName,
      nodesMapById: mapOfNodes.byId,
    };
    console.timeEnd("getCoords");

    return data;
  },
  { maxAge: 60 * 1000 }
);

export const getCoords = (): {
  nodes: CoordsWithMetaAndRelations[];
  links: any[];
  type: string;
  nodesMap: { [key: string]: CoordsWithMetaAndRelations };
  nodesMapById: { [key: string]: CoordsWithMetaAndRelations };
  constellationMap: { [key: string]: number[] };
  regionMap: { [key: string]: number[] };
} => {
  return memoizedGetCoords();
};

function centerNodes<T>(nodes: any[]): T {
  const center = { x: 0, y: 0, z: 0 };

  nodes.forEach((node) => {
    center.x += node.x;
    center.y += node.y;
    center.z += node.z;
  });

  center.x /= nodes.length;
  center.y /= nodes.length;
  center.z /= nodes.length;

  nodes.forEach((node) => {
    node.x -= center.x;
    node.y -= center.y;
    node.z -= center.z;
  });

  return nodes as T;
}

const centerGraph = (nodes: CoordsWithMeta[]) => {
  const centroid = nodes.reduce(
    (acc, node) => {
      acc.x += node.x;
      acc.y += node.y;
      acc.z += node.z;
      return acc;
    },
    { x: 0, y: 0, z: 0 }
  );
  centroid.x /= nodes.length;
  centroid.y /= nodes.length;
  centroid.z /= nodes.length;

  nodes.forEach((node) => {
    node.x -= centroid.x;
    node.y -= centroid.y;
    node.z -= centroid.z;
  });

  return centroid; // Optional, if you want to log or use it elsewhere
};
export const getScaleValue = (coords: CoordsWithMetaAndRelations[], scaleTo: number = 1000) => {
  const maxX = Math.max(...coords.map((c, idx) => idx > 100 ? Math.abs(c.x) : 0));
  const maxY = Math.max(...coords.map((c, idx) => idx > 100 ? Math.abs(c.y) : 0));
  const maxZ = Math.max(...coords.map((c, idx) => idx > 100 ? Math.abs(c.z) : 0));
  // const medX = Math.avg(...coords.map((c) => Math.abs(c.x)));
  const max = Math.max(maxX, maxY, maxZ);
  return scaleTo / max;
}
export const scaleCoordinatesToRange = (
  coords: CoordsWithMetaAndRelations[],
  scaleTo: number = 1000
): CoordsWithMetaAndRelations[] => {
  const scaleFactor = getScaleValue(coords, scaleTo);

  const scaledCoords = coords.map((c) => {
    const { locationX, locationY, locationZ, fx, fy, fz, ...rest } = c;
    return {
      ...rest,
      locationX,
      locationY,
      locationZ,
      x: c.x * scaleFactor,
      y: c.y * scaleFactor,
      z: c.z * scaleFactor,
    };
  });
  return scaledCoords;
};

export const noOp = () => { };

export const shiftCoordinatesToCenter = (
  coords: CoordsWithMetaAndRelations[],
  middle: { x: number; y: number; z: number }
): CoordsWithMetaAndRelations[] => {
  return coords.map((c) => ({
    ...c,
    x: c.x - middle.x,
    y: c.y - middle.y,
    z: c.z - middle.z,
  }));
};

export const extractMiddleOfCoords = (coords: CoordsWithMeta[]): Coords => {
  const allX = coords.reduce((acc, curr) => acc + curr.x, 0);
  const allY = coords.reduce((acc, curr) => acc + curr.y, 0);
  const allZ = coords.reduce((acc, curr) => acc + curr.z, 0);

  const len = coords.length;

  return {
    x: allX / len,
    y: allY / len,
    z: allZ / len,
  };
};

const manipulateColor = (
  colorRange: { start: string; end: string },
  colorOne: string,
  colorTwo: string,
  colorThree: string
) => {
  // align colors to range
  const start = parseInt(colorRange.start, 16);
  const end = parseInt(colorRange.end, 16);
  const colorOneInt = parseInt(colorOne, 16);
  const colorTwoInt = parseInt(colorTwo, 16);
  const colorThreeInt = parseInt(colorThree, 16);
  const manipulatedColorOne = Math.round(
    ((colorOneInt - start) / (end - start)) * 255
  );
  const manipulatedColorTwo = Math.round(
    ((colorTwoInt - start) / (end - start)) * 255
  );
  const manipulatedColorThree = Math.round(
    ((colorThreeInt - start) / (end - start)) * 255
  );
  const hexValue = `#${manipulatedColorOne.toString(16)}${manipulatedColorTwo.toString(16)}${manipulatedColorThree.toString(16)}`;
  return hexValue;
};
export const deriveColorFromConstellationId = (
  num: number,
  avoidHex = "#000000",
  minDistance = 200
) => {
  // Helper: Convert Hex to RGB
  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  // Helper: Calculate Distance Between Colors
  const colorDistance = (rgb1: any, rgb2: any) => {
    return Math.sqrt(
      Math.pow(rgb1[0] - rgb2[0], 2) +
      Math.pow(rgb1[1] - rgb2[1], 2) +
      Math.pow(rgb1[2] - rgb2[2], 2)
    );
  };

  // Hash function for deterministic results
  const seed = (num * 2654435761) % Math.pow(2, 32); // Knuth's hash
  let hue = seed % 360; // Map to hue (0-359)
  const saturation = 50 + (seed % 20); // Saturation: 50-70%
  const lightness = 60 + (seed % 10); // Lightness: 60-70%

  const avoidRgb = hexToRgb(avoidHex); // Convert avoidHex to RGB

  // Generate a color and check its distance
  let [r, g, b] = hslToRgb(hue, saturation, lightness);
  while (colorDistance([r, g, b], avoidRgb) < minDistance) {
    // Adjust hue slightly if too close to avoid color
    hue = (hue + 30) % 360;
    [r, g, b] = hslToRgb(hue, saturation, lightness);
  }

  // Convert RGB to Hex
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

// Convert HSL to RGB
const hslToRgb = (h: number, s: number, l: number) => {
  s /= 100;
  l /= 100;
  const k = (n: number) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) =>
    l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [
    Math.round(255 * f(0)),
    Math.round(255 * f(8)),
    Math.round(255 * f(4)),
  ];
};
export const deriveColorFromConstellationIdOld = (num: number) => {
  // Use a hash function to ensure a deterministic mapping
  const seed = (num * 2654435762) % Math.pow(2, 32); // Knuth's hash
  const hue = seed % 360; // Map to hue (0-359)
  const saturation = 50 + (seed % 20); // Saturation between 70-100%
  const lightness = 50 + (seed % 10); // Lightness between 70-80%

  const [r, g, b] = hslToRgb(hue, saturation, lightness);

  // Convert RGB to Hex
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

export const deriveCameraPosition = (
  startNode?: CoordsWithMeta,
  destinationNode?: CoordsWithMeta
) => {
  // Account for the flipped Y-axis (needs to be negative)
  const adjustedY = -25;
  if (startNode && destinationNode) {
    // get midpoint between the two nodes
    const midpoint = extractMiddleOfCoords([startNode, destinationNode]);
    // get the distance between the two nodes
    const distance = distanceBetweenTwoPoints(startNode, destinationNode);
    // determine the distance between the midpoint and the nodes for the purpose of zooming out
    const distRatio =
      1 + distance / Math.hypot(midpoint.x, midpoint.y, midpoint.z);
    // figure out the zoom distance based on the distance between the midpoint and the nodes to set the camera position

    return {
      x: midpoint.x * distRatio,
      y: adjustedY,
      z: midpoint.z * distRatio,
    };
  } else if (startNode ?? destinationNode) {
    const focusNode = startNode ?? (destinationNode as CoordsWithMeta);
    const distance = 15;
    const distRatio =
      1 + distance / Math.hypot(focusNode.x, focusNode.y, focusNode.z);
    return {
      x: focusNode.x * distRatio,
      y: adjustedY,
      z: focusNode.z * distRatio,
    };
  }
  return { x: 0, z: 0, y: 250 };
};

export const deriveFocusPosition = (
  startNode?: CoordsWithMeta,
  destinationNode?: CoordsWithMeta
) => {
  // if (startNode && destinationNode) {
  //   // get midpoint between the two nodes
  //   const midpoint = extractMiddleOfCoords([startNode, destinationNode]);
  //   // Account for the flipped Y-axis (needs to be negative)
  //   const focusPointAdjustedY = -midpoint.y;
  //   return {
  //     x: midpoint.x,
  //     y: focusPointAdjustedY,
  //     z: midpoint.z,
  //   };
  // } else
  if (startNode ?? destinationNode) {
    const focusNode = startNode ?? (destinationNode as CoordsWithMeta);
    // Account for the flipped Y-axis (needs to be negative)
    const focusPointAdjustedY = -focusNode.y;

    return { x: focusNode.x, y: focusPointAdjustedY, z: focusNode.z };
  }
  return { x: 0, z: 0, y: 0 };
};


const deriveColorsOfNode = (state: IMapState, map: Record<string, MapItem>, colors: Float32Array) => (node: any, i: number) => {
  // if (i < 10) {
  //   console.log("node", node, colorMapKey[state.mapDisplayType], node[colorMapKey[state.mapDisplayType]]);
  // }
  const color = map[node[mapDisplayTypeKey[state.mapDisplayType]]].color;
  colors[i * 3] = color.r;
  colors[i * 3 + 1] = color.g;
  colors[i * 3 + 2] = color.b;
  return
}

const deriveRadiiOfNode = (state: IMapState, map: Record<string, MapItem>, radii: Float32Array) => (node: any, i: number) => {
  radii[i] = map[node[mapDisplayTypeKey[state.mapDisplayType]]].radius;
  return
}

export const deriveColorsAndRadii = (state: IMapState, map: Record<string, MapItem>, colors: Float32Array, radii: Float32Array) => (node: any, i: number) => {
  deriveColorsOfNode(state, map, colors)(node, i);
  deriveRadiiOfNode(state, map, radii)(node, i);
  return;
}

const primaryHighlighterColor = new THREE.Color(COLOR_THEME.npcStationColor);
const secondaryHighlighterColor = new THREE.Color(
  COLOR_THEME.selectedDestinationSystemColor
);

interface MapItem {
  color: THREE.Color;
  radius: number;
}

export const npcStationsColorsMap = (() =>
  (allSystems as any[]).reduce(
    (acc, props) => {
      if (
        npcStationLocations[
          props.solarSystemId as keyof typeof npcStationLocations
        ]
      ) {
        const color =
          npcStationLocations[
            props.solarSystemId as keyof typeof npcStationLocations
          ] === "85226"
            ? secondaryHighlighterColor
            : primaryHighlighterColor;
        acc[props.solarSystemId] = {
          color: color,
          radius: SETTINGS.highlightedNodeRadius,
        };
      } else {
        acc[props.solarSystemId] = {
          color: new THREE.Color(COLOR_THEME.baseSystemColor),
          radius: SETTINGS.nodeRadius,
        };
      }
      return acc;
    },
    {} as Record<string, MapItem>
  ))();

const deriveRadiusMapValuesBetweenTwoRangesOnRange = (
  map: Record<string, number>,
  range = [3, 15]
) => {
  // determine the range based on the upper and lower bound of the map values
  const values = Object.values(map);
  const min = Math.min(...values);
  const max = Math.max(...values);
  const rangeMin = range[0];
  const rangeMax = range[1];
  // then create the value based on where it lies between value min and max
  const fn = (value: number) => {
    if (value === 0) {
      return 0;
    }
    return rangeMin + ((value - min) / (max - min)) * (rangeMax - rangeMin);
  };
  return Object.keys(map).reduce<Record<string, number>>((acc, key) => {
    acc[key] = fn(map[key]);
    return acc;
  }, {});
};
export const buildInstanceMap = (
  mapDisplayType: MapDisplayType,
  regionMap: any,
  activeAssembliesCountMap: Record<string, number>
): Record<string, MapItem> => {
  switch (mapDisplayType) {
    case MapDisplayType.NPC_STATION:
      return npcStationsColorsMap;
    case MapDisplayType.ACTIVE_ASSEMBLIES_COUNT: {
      const radiusMap = deriveRadiusMapValuesBetweenTwoRangesOnRange(
        activeAssembliesCountMap
      );
      return Object.keys(activeAssembliesCountMap).reduce<
        Record<string, MapItem>
      >((acc, id, idx) => {
        acc[id] = {
          color:
            radiusMap[id] === 0
              ? new THREE.Color(COLOR_THEME.baseSystemColor)
              : primaryHighlighterColor,
          radius: radiusMap[id] === 0 ? SETTINGS.nodeRadius : radiusMap[id],
        };
        return acc;
      }, {});
    }
    case MapDisplayType.REGION:
    default:
      return Object.keys(regionMap).reduce<Record<string, MapItem>>(
        (acc, id) => {
          acc[id] = {
            color: new THREE.Color(
              deriveColorFromConstellationId(
                parseInt(id),
                `0x${COLOR_THEME.selectedSystemColor.toString(16)}`
              )
            ),
            radius: SETTINGS.nodeRadius,
          };
          return acc;
        },
        {}
      );
  }
};