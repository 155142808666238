import React, { ReactNode } from "react";
import { TextField } from "@mui/material";
import { converBigIntToDisplayNumber } from "@/utils";

const validValues = {
  "0": true,
  "1": true,
  "2": true,
  "3": true,
  "4": true,
  "5": true,
  "6": true,
  "7": true,
  "8": true,
  "9": true,
  ".": true,
  k: true,
  m: true,
  b: true,
};

const parseShorthand = (
  value: string | number,
  includeDecimals = false,
  stripTrailing = false
) => {
  if (typeof value === "string") {
    if (value === "") {
      console.log("empty string");
      return 0;
    }
    // remove all non-numeric characters except for k, m, b, and .
    value = value.replace(/[^0-9.kmb.]/g, "");
    const suffix = value.slice(-1).toLowerCase();

    const number = parseFloat(value.slice(0, -1));
    switch (suffix) {
      case "k":
        return number * 1_000;
      case "m":
        return number * 1_000_000;
      case "b":
        return number * 1_000_000_000;
      case ".":
        return includeDecimals ? value : parseFloat(value);
      default:
        return includeDecimals ? value : parseFloat(value);
    }
  }
  return value;
};
/**
 * Component for editing text fields with different input types.
 *
 * @param inputType - The type of input: "string", "multiline", or "numerical".
 * @param defaultValue - The default value for the text field.
 * @param fieldName - The name of the field being input.
 * @param onChange - Function called when the value changes.
 * @param placeholder - Optional placeholder text for the field.
 *
 * @returns JSX Element representing the text editing component.
 */
export default function StringNumberInput(props: {
  defaultValue: React.MutableRefObject<string | number> | ReactNode;
  fieldName: string;
  fieldVal: string | number | undefined;
  includeDecimals: boolean;
  onChange: (value: string | number | undefined) => void;
  placeholder?: string;
}): JSX.Element {
  const {
    defaultValue,
    includeDecimals,
    fieldName,
    onChange,
    fieldVal,
    placeholder,
  } = props;

  const defaultIsString = () => {
    if (typeof props.defaultValue === "string") return props.defaultValue;
  };
  const [value, setValue] = React.useState<string | number | undefined>(
    defaultIsString() ? placeholder : fieldName
  );
  // console.log("fieldval", fieldVal);
  const handleChange = (value: any) => {
    console.log(value);
    if (
      Number.isNaN(
        parseFloat(parseShorthand(value, includeDecimals).toString())
      )
    ) {
      console.error("Invalid input");
      return;
    }

    onChange(value);
  };
  const handleLocalChange = (value: any) => {
    console.log(value);
    if (
      Number.isNaN(
        parseFloat(parseShorthand(value, includeDecimals).toString())
      )
    ) {
      console.error("Invalid input");
      return;
    }

    setValue(value);
  };

  const handleBlur = (value: any) => {
    console.log(value);
    if (
      Number.isNaN(
        parseFloat(parseShorthand(value, includeDecimals).toString())
      )
    ) {
      console.error("Invalid input");
      return;
    }

    onChange(value);
  };

  const fieldId = fieldName.replace(/\s/g, "");

  return (
    <div className="TextField grid my-1 w-full" id={`edit-${fieldId}`}>
      <span className="text-xs font-bol uppercase">{fieldName}</span>
      <TextField
        multiline={false}
        minRows={undefined}
        id={`input-${fieldId}`}
        placeholder={defaultIsString() ?? placeholder ?? fieldName}
        value={value}
        className="flex-grow"
        onChange={(e) =>
          handleLocalChange(parseShorthand(e.target.value, includeDecimals))
        }
        onBlur={(e) =>
          handleBlur(parseShorthand(e.target.value, includeDecimals))
        }
        autoComplete="off"
        autoFocus
      />
    </div>
  );
}
