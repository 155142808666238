import { useEffect, useReducer, useState } from "react";
import { MenuItem, Select } from "@mui/material";

import {
  createBaseState,
  ITradeWindowState,
  tradeWindowReducer,
} from "./tradeWindow.reducer";
import { OrderType } from "@/types/orders";
import Input from "@/components/Input";
import { IItemMarketState } from "./singleItemMarket.reducer";
import {
  minBigInt,
  sortBigIntAscending,
  sortBigIntDescending,
} from "@/components/DexSSU/utils";
import ErrorMsg from "@/components/ErrorMsg";
import { useMUD } from "@/MUDContext";
import {
  useConnection,
  useNotification,
  useSmartObject,
} from "@eveworld/contexts";
import AnimatedNumber from "@/components/AnimatedNumber";
import { converBigIntToDisplayNumber } from "@/utils/displayFormat";
import FloatingAnimatedNumber from "@/components/FloatingAnimatedNumber";
import { convertFloatToBigInt } from "@/utils/decimals";
import { EveButton } from "@eveworld/ui-components";
import { maxUint256 } from "viem";
import { Severity } from "@eveworld/types";
import { useEffectOnce } from "@/utils/hooks/useEffectOnce";
import {
  adjustAndCalculateTotalPrice,
  adjustAndReturnUnitPrice,
  getAdjustedUnitPrice,
} from "./utils";
import { DEFAULT_BASIS_POINTS_DIVISOR } from "@/constants";
import { usePendingTxManager } from "@/hooks/PendingTrasactionContext";
import { IMarketState } from "./types";
import StringNumberInput from "@/components/StringNumberInput";

interface ITradeWindowProps {
  itemId: string | null;
  marketState: IMarketState;
  singleMarketState: IItemMarketState;
  tradeAssetTicker: string;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
}

const TradeWindow = ({
  itemId,
  marketState,
  singleMarketState,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
}: ITradeWindowProps) => {
  const { addTransaction } = usePendingTxManager();
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const [state, dispatch] = useReducer(
    tradeWindowReducer,
    createBaseState(itemId, tradeAssetTicker)
  );
  const { notify } = useNotification();
  const { smartAssembly, smartCharacter } = useSmartObject();
  const {
    systemCalls: {
      registerBuyOrder,
      getFeeBasisPoints,
      registerSellOrder,
      executeMarketOrder,
      getCheckIsItemInSSU,
    },
  } = useMUD();
  const { publicClient } = useConnection();

  const checkSufficientSpecified = () => {
    if (
      !state.price.value &&
      state.orderType !== OrderType.MARKET_BUY &&
      state.orderType !== OrderType.MARKET_SELL
    ) {
      dispatch({
        type: "SET_PRICE_ERROR",
        payload: "No price specified.",
      });
      return false;
    }
    dispatch({ type: "SET_PRICE_ERROR", payload: null });
    if (!state.quantity.value) {
      dispatch({
        type: "SET_QUANTITY_ERROR",
        payload: "No quantity specified.",
      });
      return false;
    }
    return true;
  };
  // used for MARKET_BUY and LIMIT_BUY
  const checksSufficientTradeAssetBalanceToPurchase = () => {
    if (
      !checkSufficientSpecified() ||
      !state.quantity.value ||
      !state.price.value
    ) {
      return false;
    }
    const derivedCostWei = state.quantity.value * state.price.value;
    if (derivedCostWei >= tradeAssetBalance) {
      dispatch({
        type: "SET_QUANTITY_ERROR",
        payload: "Insufficient token balance to purchase.",
      });
      return false;
    }
    return true;
  };

  // used for MARKET_BUY
  const checkSufficientAssetsPresentForPurchase = () => {
    if (!checkSufficientSpecified() || !state.quantity.value) {
      return false;
    }
    if (
      state.quantity.value >
      marketState
        .itemOrders!.filter(
          (order) =>
            order.orderType === OrderType.LIMIT_SELL &&
            order.smartObjectId === BigInt(smartAssembly?.id!)
        )
        .reduce((acc, order) => acc + order.quantityRemaining, 0n)
    ) {
      dispatch({
        type: "SET_QUANTITY_ERROR",
        payload: "Insufficient quantity available to purchase at current hub.",
      });
      return false;
    }
    return true;
  };

  // used for MARKET_SELL
  const checkSufficientAssetsPresentForSale = () => {
    if (!checkSufficientSpecified() || !state.quantity.value) {
      return false;
    }
    if (
      state.quantity.value >
      marketState
        .itemOrders!.filter((order) => order.orderType === OrderType.LIMIT_BUY)
        .reduce((acc, order) => acc + order.quantityRemaining, 0n)
    ) {
      dispatch({
        type: "SET_QUANTITY_ERROR",
        payload: "Insufficient buy orders to sell specified quantity.",
      });
      return false;
    }
    return true;
  };

  // used for LIMIT_SELL
  const checkSufficientAssetsOwnedToSell = () => {
    if (!checkSufficientSpecified() || !state.quantity.value) {
      return false;
    }
    // check if a user owns sufficient quantity of the item to sell
    if (!singleMarketState.quantityOwned) {
      dispatch({
        type: "SET_QUANTITY_ERROR",
        payload: "Can't check quantity owned.",
      });
      return;
    }
    if (state.quantity.value > singleMarketState.quantityOwned) {
      dispatch({
        type: "SET_QUANTITY_ERROR",
        payload: "Insufficient quantity available to sell.",
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    switch (state.orderType) {
      case OrderType.LIMIT_BUY: {
        const limitBuyRes = checksSufficientTradeAssetBalanceToPurchase();
        if (limitBuyRes) {
          dispatch({
            type: "CLEAR_ERRORS",
          });
        }
        break;
      }
      case OrderType.MARKET_BUY: {
        const marketBuyRes = checkSufficientAssetsPresentForPurchase();
        if (marketBuyRes) {
          dispatch({
            type: "CLEAR_ERRORS",
          });
        }
        break;
      }
      case OrderType.LIMIT_SELL: {
        const limitSellRes = checkSufficientAssetsOwnedToSell();
        if (limitSellRes) {
          dispatch({
            type: "CLEAR_ERRORS",
          });
        }
        break;
      }
      case OrderType.MARKET_SELL: {
        const marketSellRes = checkSufficientAssetsPresentForSale();
        if (marketSellRes) {
          dispatch({
            type: "CLEAR_ERRORS",
          });
        }
        break;
      }
    }
  }, [state.price.value, state.quantity.value, state.orderType]);

  const handleQuantityChange = (value: string | number | null) => {
    if (value === null) {
      dispatch({
        type: "SET_QUANTITY",
        payload: {
          value: 0n,
          error: "No Quantity Specified",
        },
      });
      return;
    }
    const derivedQuantity = BigInt(value);
    if (derivedQuantity === 0n) {
      dispatch({
        type: "SET_QUANTITY",
        payload: {
          value: derivedQuantity,
          error: "Quantity cannot be 0",
        },
      });
      return;
    }
    dispatch({
      type: "SET_QUANTITY",
      payload: { value: derivedQuantity },
    });
  };

  useEffect(() => {
    if (!smartAssembly?.id) {
      return;
    }
    if (state.orderType === OrderType.MARKET_BUY) {
      // calculate the price from available buy / sell orders.
      // if it's a market buy, the price is the average of the sell
      if (
        !marketState.itemOrders ||
        marketState.itemOrders.length === 0 ||
        state.quantity.value === null ||
        state.quantity.value === 0n
      ) {
        return;
      }
      const orders = marketState.itemOrders
        .filter(
          (order) =>
            order.orderType === OrderType.LIMIT_SELL &&
            order.smartObjectId === BigInt(smartAssembly?.id)
        )
        .sort((a, b) => sortBigIntAscending(a.priceWithFee, b.priceWithFee));
      console.log(orders);
      if (orders.length === 0) {
        return;
      }
      const orderExecutionRes = orders.reduce(
        (acc, order) => {
          if (acc.quantityRemainingToFill === 0n) {
            return acc;
          }
          // determine how much the user can purchase, then add to the accumulator and subtract from the user's balance
          const amountToPurchase = minBigInt(
            acc.quantityRemainingToFill,
            order.quantityRemaining
          );
          acc = {
            quantityRemainingToFill:
              acc.quantityRemainingToFill - amountToPurchase,
            totalPrice: acc.totalPrice + order.priceWithFee * amountToPurchase,
          };
          return acc;
        },
        {
          quantityRemainingToFill: state.quantity.value,
          totalPrice: 0n,
        }
      );
      const derivedPrice =
        orderExecutionRes.totalPrice /
        (state.quantity.value - orderExecutionRes.quantityRemainingToFill);
      console.log("set derived market buy price: ", derivedPrice);
      dispatch({
        type: "SET_PRICE",
        payload: {
          value: derivedPrice,
        },
      });
      console.log("orderExecutionRes", orderExecutionRes);
    } else if (state.orderType === OrderType.MARKET_SELL) {
      // calculate the price from available buy / sell orders.
      // if it's a market buy, the price is the average of the sell
      if (
        !marketState.itemOrders ||
        marketState.itemOrders.length === 0 ||
        state.quantity.value === null ||
        state.quantity.value === 0n
      ) {
        return;
      }
      const orders = marketState.itemOrders
        .filter(
          (order) =>
            order.orderType === OrderType.LIMIT_BUY &&
            order.smartObjectId === BigInt(smartAssembly?.id)
        )
        .sort((a, b) => sortBigIntDescending(a.priceWithFee, b.priceWithFee));
      const orderExecutionRes = orders.reduce(
        (acc, order) => {
          if (acc.quantityRemainingToFill === 0n) {
            return acc;
          }
          // determine how much the user can purchase, then add to the accumulator and subtract from the user's balance
          const amountToPurchase = minBigInt(
            acc.quantityRemainingToFill,
            order.quantityRemaining
          );
          acc = {
            quantityRemainingToFill:
              acc.quantityRemainingToFill - amountToPurchase,
            totalPrice: acc.totalPrice + order.priceWithFee * amountToPurchase,
          };
          return acc;
        },
        {
          quantityRemainingToFill: state.quantity.value,
          totalPrice: 0n,
        }
      );
      const derivedPrice = orderExecutionRes.totalPrice / state.quantity.value;
      dispatch({
        type: "SET_PRICE",
        payload: {
          value: derivedPrice,
        },
      });
    }
    lookupAndSetFeeAdjustedPrice();
  }, [state.orderType, state.quantity.value, marketState.itemOrders]);
  // console.log("STaTe", state);
  const handlePriceChange = (value: string | null) => {
    if (value === null) {
      dispatch({
        type: "SET_PRICE",
        payload: {
          value: 0n,
          error: "No Price Specified",
        },
      });
      return;
    }

    const derivedPrice = convertFloatToBigInt(
      parseFloat(value),
      tradeAssetDecimals
    );
    if (derivedPrice === 0n) {
      dispatch({
        type: "SET_PRICE",
        payload: {
          value: derivedPrice,
          error: "Price cannot be 0",
        },
      });
      return;
    }
    dispatch({
      type: "SET_PRICE",
      payload: { value: derivedPrice },
    });
  };
  useEffectOnce(() => {
    if (smartAssembly?.id && state.itemId) {
      getCheckIsItemInSSU(BigInt(smartAssembly?.id), state.itemId);
    }
    const interval = setInterval(() => {
      if (smartAssembly?.id && state.itemId) {
        getCheckIsItemInSSU(BigInt(smartAssembly?.id), state.itemId);
      }
    }, 3000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (state.pendingTxs.length === 0) {
      return;
    }
    const result = state.pendingTxs.reduce((acc, txHash) => {
      if (acc) {
        return acc;
      }
      return acc;
    }, false);
  }, [state.pendingTxs]);
  const handleSubmit = () => {
    console.log("Submitting trade", state);
    // set trade as submitting
    if (!smartAssembly?.id) {
      notify({
        type: Severity.Error,
        message: "No smart assembly found. Try re-logging.",
      });
      return;
    }
    if (!smartCharacter?.id) {
      notify({
        type: Severity.Error,
        message: "No smart character found. Try re-logging.",
      });
      return;
    }
    if (!state.price.value) {
      notify({
        type: Severity.Error,
        message: "No price specified.",
      });
      return;
    }
    if (!state.quantity.value) {
      notify({
        type: Severity.Error,
        message: "No quantity specified.",
      });
      return;
    }
    if (!state.itemId) {
      notify({
        type: Severity.Error,
        message: "No item id specified.",
      });
      return;
    }
    if (!state.price.feeAdjustedPrice) {
      notify({
        type: Severity.Error,
        message: "No fee adjusted price specified.",
      });
      return;
    }
    dispatch({ type: "SET_SUBMITTING_ORDER" });
    switch (state.orderType) {
      case OrderType.LIMIT_BUY: {
        console.log("Submitting limit buy for order: ", {
          smartId: BigInt(smartAssembly?.id),
          quantity: BigInt(state.quantity.value),
          itemId: state.itemId,
          price: state.price.value,
          priceWithFee: state.price.feeAdjustedPrice,
          character: BigInt(smartCharacter?.id),
        });
        registerBuyOrder(
          BigInt(smartAssembly?.id),
          BigInt(state.quantity.value),
          state.price.value,
          state.price.feeAdjustedPrice,
          state.itemId,
          BigInt(smartCharacter?.id)
        )
          .then((txHash) => {
            console.log("Limit Buy txHash", txHash);
            if (txHash) {
              dispatch({ type: "MARK_ORDER_SUBMITTED" });
            }
            addTransaction(txHash.toString() as `0x${string}`);
            return txHash;
          })
          .catch((err) => {
            console.error("Error submitting limit buy", err);
            dispatch({ type: "MARK_ORDER_REJECTED" });
          });

        break;
      }
      case OrderType.MARKET_BUY: {
        console.log("Submitting market buy for order: ", {
          smartId: BigInt(smartAssembly?.id),
          quantity: BigInt(state.quantity.value),
          itemId: state.itemId,
          price: state.price.value,
          priceWithFee: state.price.feeAdjustedPrice,
          character: BigInt(smartCharacter?.id),
        });
        executeMarketOrder(
          BigInt(smartAssembly?.id),
          state.itemId,
          state.price.feeAdjustedPrice,
          BigInt(state.quantity.value),
          state.orderType
        )
          .then((txHash) => {
            console.log("Market Buy txHash", txHash);
            if (txHash) {
              dispatch({ type: "MARK_ORDER_SUBMITTED" });
            }
            addTransaction(txHash.toString() as `0x${string}`);
            return txHash;
          })
          .catch((err) => {
            console.error("Error submitting market buy", err);
            dispatch({ type: "MARK_ORDER_REJECTED" });

            // notify({
            //   type: Severity.Error,
            //   message: "Error submitting market buy. Try again.",
            // });
          });

        break;
      }
      case OrderType.LIMIT_SELL: {
        console.log("Submitting limit sell for order: ", {
          smartId: BigInt(smartAssembly?.id),
          quantity: BigInt(state.quantity.value),
          itemId: state.itemId,
          price: state.price.value,
          priceWithFee: state.price.feeAdjustedPrice,
          character: BigInt(smartCharacter?.id),
        });
        registerSellOrder(
          BigInt(smartAssembly?.id),
          BigInt(state.quantity.value),
          state.price.value,
          state.itemId,
          BigInt(smartCharacter?.id)
        )
          .then((txHash) => {
            console.log("Limit sell txHash", txHash);
            if (txHash) {
              dispatch({ type: "MARK_ORDER_SUBMITTED" });
            }
            addTransaction(txHash.toString() as `0x${string}`);
            return txHash;
          })
          .catch((err) => {
            console.error("Error submitting limit sell", err);
            dispatch({ type: "MARK_ORDER_REJECTED" });

            // notify({
            //   type: Severity.Error,
            //   message: "Error submitting limit sell. Try again.",
            // });
          });

        break;
      }
      case OrderType.MARKET_SELL: {
        console.log("Submitting limit buy for order: ", {
          smartId: BigInt(smartAssembly?.id),
          quantity: BigInt(state.quantity.value),
          itemId: state.itemId,
          price: state.price.value,
          priceWithFee: state.price.feeAdjustedPrice,
          character: BigInt(smartCharacter?.id),
        });
        executeMarketOrder(
          BigInt(smartAssembly?.id),
          state.itemId,
          state.price.value,
          BigInt(state.quantity.value),
          state.orderType
        )
          .then((txHash) => {
            console.log("Market sell txHash", txHash);
            if (txHash) {
              dispatch({ type: "MARK_ORDER_SUBMITTED" });
            }
            addTransaction(txHash.toString() as `0x${string}`);
            return txHash;
          })
          .catch((err) => {
            console.error("Error submitting market sell", err);
            dispatch({ type: "MARK_ORDER_REJECTED" });

            // notify({
            //   type: Severity.Error,
            //   message: "Error submitting market sell. Try again.",
            // });
          });

        break;
      }
    }
  };

  useEffect(() => {
    lookupAndSetFeeAdjustedPrice();
  }, [state.price.value]);

  const lookupAndSetFeeAdjustedPrice = async () => {
    console.log("Looking up fee adjusted price", state.price.value);
    if (state.price.value === null) {
      return;
    }
    if (
      state.orderType === OrderType.MARKET_BUY ||
      state.orderType === OrderType.MARKET_SELL
    ) {
      console.log(
        "Setting fee adjusted price to market price",
        state.price.value
      );
      dispatch({
        type: "SET_FEE_ADJUSTED_PRICE",
        payload: { feeBasisPoints: 0n, feeAdjustedPrice: state.price.value },
      });
      return;
    }
    if (!state.itemId || !smartAssembly?.id || !smartCharacter?.id) {
      console.error("Missing required fields to calculate fee adjusted price", {
        itemId: state.itemId,
        smartAssembly: smartAssembly?.id,
        smartCharacter: smartCharacter?.id,
        price: state.price.value,
      });
      return;
    }
    getFeeBasisPoints(BigInt(smartAssembly.id), state.itemId)
      .then((feeBasisPoints) => {
        if (state.price.value === null) {
          return;
        }
        const feeAdjustedAmount = getAdjustedUnitPrice(
          state.price.value,
          tradeAssetDecimals,
          feeBasisPoints
        );
        dispatch({
          type: "SET_FEE_ADJUSTED_PRICE",
          payload: { feeBasisPoints, feeAdjustedPrice: feeAdjustedAmount },
        });
      })
      .catch((err) => {
        console.error("Error getting fee adjusted price", err);
        notify({
          type: Severity.Error,
          message: "Error getting fee adjusted price",
        });
      });
  };
  const handleOrderTypeChange = (orderType: OrderType) => {
    if (!smartAssembly?.id) {
      notify({
        type: Severity.Error,
        message: "No smart assembly found. Try re-logging.",
      });
      return;
    }
    // if ordertype changes, the maximum quantity available to send changes as well
    // (i.e - if it's a limit sell, the max quantity is the quantity of the item that the user has whereas if it's a market buy,
    // the max quantity is the balance of the trade asset at the current location)
    switch (orderType) {
      case OrderType.LIMIT_BUY: {
        const price = state.price.value || 0n;
        dispatch({
          type: "SET_ORDER_TYPE",
          payload: {
            orderType,
          },
        });
        break;
      }
      case OrderType.MARKET_BUY: {
        // Max quantity here should be the minimum between:
        // - the amount that the user has of eve tokens to trade for this item (dependent on the current location's buy orders)
        // - the amount that the station has of the trade asset
        // const userBalance =
        const maxPurchaseableByUser = marketState
          .itemOrders!.filter(
            (order) =>
              order.orderType === OrderType.LIMIT_SELL &&
              order.smartObjectId === BigInt(smartAssembly?.id)
          )
          .sort((a, b) => sortBigIntAscending(a.priceWithFee, b.priceWithFee))
          .reduce(
            (acc, order) => {
              if (acc.remainingUserBalance === 0n) {
                return acc;
              }
              // determine how much the user can purchase, then add to the accumulator and subtract from the user's balance
              const costToPurchaseWholeOrder =
                order.priceWithFee * order.quantityRemaining;
              // get min of the two values
              const valueUserCanPurchase = minBigInt(
                costToPurchaseWholeOrder,
                tradeAssetBalance
              );
              acc = {
                quantityToPurchase:
                  acc.quantityToPurchase +
                  valueUserCanPurchase / order.priceWithFee,
                remainingUserBalance:
                  acc.remainingUserBalance - valueUserCanPurchase,
              };
              return acc;
            },
            { quantityToPurchase: 0n, remainingUserBalance: tradeAssetBalance }
          );
        console.debug(
          "maxPurchaseableByUser",
          maxPurchaseableByUser.quantityToPurchase,
          maxPurchaseableByUser.remainingUserBalance
        );
        dispatch({
          type: "SET_ORDER_TYPE",
          payload: {
            orderType,
          },
        });
        break;
      }
      case OrderType.LIMIT_SELL: {
        // Should have max quantity being the amount that the user has of this item
        dispatch({
          type: "SET_ORDER_TYPE",
          payload: { orderType }, // @todo: add users balance of item
        });
        break;
      }
      case OrderType.MARKET_SELL: {
        // Should have max quantity being the minimum between:
        // the amount that the user has of this item
        // the sum of the buy orders that are currently open for this item at the current location
        const maxQuantityThreshold = Math.min(
          parseInt(
            marketState
              .itemOrders!.reduce((acc, order) => {
                if (
                  order.orderType === OrderType.LIMIT_BUY &&
                  order.smartObjectId === BigInt(smartAssembly?.id)
                ) {
                  return acc + order.quantityRemaining;
                }
                return acc;
              }, 0n)
              .toString()
          ),
          singleMarketState.quantityOwned
            ? parseInt(singleMarketState.quantityOwned?.toString())
            : 0 // @todo: add users balance
        );
        dispatch({
          type: "SET_ORDER_TYPE",
          payload: {
            orderType,
          },
        });
        break;
      }
      default:
        break;
    }
    lookupAndSetFeeAdjustedPrice();
  };

  if (state.price.value === null) {
    return <div>Loading...</div>;
  }

  const toggleShouldDisplay = () => {
    setShouldDisplay(!shouldDisplay);
  };
  // console.log("errors", state.price.error, state.quantity.error);
  // console.log("State", state);
  return (
    <div className="">
      <div className="Quantum-Container Text font-bold">
        <button onClick={toggleShouldDisplay} className="w-full">
          <div className="flex flex-row justify-between">
            <span>Trade</span>
            <span className="font-bold px-2">{shouldDisplay ? "-" : "+"}</span>
          </div>
        </button>
      </div>

      {shouldDisplay ? (
        <div className="Quantum-Container flex flex-col">
          <div className="flex flex-row justify-between text-xs">
            <div style={{ alignSelf: "center" }}>Order Type</div>
            <div
              className="flex flex-col justify-between"
              style={{ width: "150px" }}
            >
              <Select
                className="border-brightquantum border w-full"
                value={state.orderType}
                onChange={(e) =>
                  handleOrderTypeChange(e.target.value as OrderType)
                }
              >
                <MenuItem className="w-full" value={OrderType.LIMIT_BUY}>
                  Limit Buy
                </MenuItem>
                <MenuItem value={OrderType.LIMIT_SELL}>Limit Sell</MenuItem>
                <MenuItem value={OrderType.MARKET_BUY}>Market Buy</MenuItem>
                <MenuItem value={OrderType.MARKET_SELL}>Market Sell</MenuItem>
              </Select>
            </div>
          </div>
          <div className="flex flex-row justify-between text-xs">
            <div style={{ alignSelf: "center" }}>Quantity</div>
            <div className="flex">
              <StringNumberInput
                defaultValue={"0"}
                fieldName={``}
                placeholder="0"
                includeDecimals={false}
                fieldVal={parseInt((state.quantity.value || 0n).toString())}
                onChange={(qty: any) => {
                  if (isNaN(qty)) return;
                  handleQuantityChange(parseInt(qty.toString()));
                }}
              />
            </div>
          </div>
          <div className="text-xs">
            {state.quantity.error ? (
              <ErrorMsg message={state.quantity.error} />
            ) : (
              <div> </div>
            )}
          </div>
          <div className="flex flex-row justify-between text-xs">
            <div style={{ alignSelf: "center" }}>Market Price</div>
            {singleMarketState.marketPrice !== null ? (
              (() => {
                const totalPriceToDisplay = converBigIntToDisplayNumber(
                  singleMarketState.marketPrice,
                  tradeAssetDecimals
                );
                // console.log(
                //   "totalPriceToDisplay",
                //   totalPriceToDisplay,
                //   singleMarketState.marketPrice,
                //   totalPriceToDisplay.split("."),
                //   parseInt(totalPriceToDisplay.split(".")[0]),
                //   parseInt(totalPriceToDisplay.split(".")[1].slice(0, 6))
                // );
                if (singleMarketState.marketPrice !== 0n) {
                  return (
                    <div className="flex flex-row">
                      <FloatingAnimatedNumber
                        wholeNumber={parseInt(
                          totalPriceToDisplay.split(".")[0]
                        )}
                        decimalWholeNumber={parseInt(
                          totalPriceToDisplay.split(".")[1].slice(0, 6)
                        )}
                        duration={250}
                      />
                      <span style={{ paddingLeft: "0.5rem" }}>
                        {tradeAssetTicker}
                      </span>
                    </div>
                  );
                } else {
                  return <>Insufficient data to provide market price.</>;
                }
              })()
            ) : (
              <></>
            )}
          </div>
          {state.orderType !== OrderType.MARKET_BUY &&
            state.orderType !== OrderType.MARKET_SELL && (
              <div className="flex flex-row justify-between text-xs">
                <div style={{ alignSelf: "center" }}>List Price</div>
                <div className="flex flex-col">
                  <StringNumberInput
                    defaultValue={"1"}
                    fieldName={``}
                    placeholder="0"
                    fieldVal={parseFloat(state.price.value.toString())}
                    includeDecimals={true}
                    onChange={(price: any) => {
                      // console.debug("price", price);
                      if (isNaN(price)) return;
                      handlePriceChange(price.toString());
                    }}
                  />
                </div>
              </div>
            )}
          <div className="flex flex-row justify-between text-xs">
            <div style={{ alignSelf: "center" }}>
              {" "}
              {state.orderType === OrderType.MARKET_BUY ||
              state.orderType === OrderType.LIMIT_BUY
                ? `Unit Price (incl. fee)`
                : `Cost to purchaser (unit price + fee)`}
            </div>
            {state.price.value !== null &&
            singleMarketState.marketPrice !== null ? (
              (() => {
                const feePercentage = parseFloat(
                  (
                    parseInt(state.price.feeBasisPoints.toString()) /
                    DEFAULT_BASIS_POINTS_DIVISOR
                  ).toString()
                );
                const priceToDisplay = adjustAndCalculateTotalPrice(
                  state.price.value,
                  1n,
                  tradeAssetDecimals,
                  feePercentage
                );

                const marketPriceToDisplay = converBigIntToDisplayNumber(
                  singleMarketState.marketPrice || 0n,
                  tradeAssetDecimals
                );
                const diffPercentageToDisplay =
                  (parseFloat(priceToDisplay) /
                    parseFloat(marketPriceToDisplay) -
                    1) *
                  100;
                return (
                  <div className="flex flex-row">
                    {singleMarketState.marketPrice ? (
                      <>
                        <FloatingAnimatedNumber
                          wholeNumber={parseInt(
                            diffPercentageToDisplay.toFixed(2).split(".")[0]
                          )}
                          decimalWholeNumber={parseInt(
                            diffPercentageToDisplay.toFixed(2).split(".")[1]
                          )}
                          decimalPlacesToDisplay={2}
                          duration={250}
                          isPercentage={true}
                          style={{
                            color:
                              diffPercentageToDisplay > 0 ? "red" : "green",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="pl-2">
                      <FloatingAnimatedNumber
                        wholeNumber={parseInt(priceToDisplay.split(".")[0])}
                        decimalWholeNumber={parseInt(
                          priceToDisplay.split(".")[1]
                        )}
                        duration={250}
                      />
                    </div>
                    <span style={{ paddingLeft: "0.5rem" }}>
                      {tradeAssetTicker}
                    </span>
                  </div>
                );
              })()
            ) : (
              <></>
            )}
          </div>
          <div className="text-xs">
            {state.price.error ? (
              <ErrorMsg message={state.price.error} />
            ) : (
              <div> </div>
            )}
          </div>
          <div className="flex flex-row justify-between text-xs">
            <div style={{ alignSelf: "center" }}>
              {state.orderType === OrderType.MARKET_BUY ||
              state.orderType === OrderType.LIMIT_BUY
                ? `Total Price`
                : `Total Cost to Purchaser`}
            </div>
            {state.quantity.value !== null && state.price.value !== null ? (
              (() => {
                const feePercentage = parseFloat(
                  (
                    parseInt(state.price.feeBasisPoints.toString()) /
                    DEFAULT_BASIS_POINTS_DIVISOR
                  ).toString()
                );
                const totalPriceToDisplay = adjustAndCalculateTotalPrice(
                  state.price.value,
                  state.quantity.value,
                  tradeAssetDecimals,
                  feePercentage
                );
                // console.log(
                //   "converted price ",
                //   state.price.value,
                //   state.quantity.value,
                //   feePercentage,
                //   state.price.feeBasisPoints,
                //   DEFAULT_BASIS_POINTS_DIVISOR,
                //   totalPriceToDisplay
                // );
                return (
                  <div className="flex flex-row">
                    <FloatingAnimatedNumber
                      wholeNumber={parseInt(totalPriceToDisplay.split(".")[0])}
                      decimalWholeNumber={parseInt(
                        totalPriceToDisplay.split(".")[1]
                      )}
                      duration={250}
                    />
                    <span style={{ paddingLeft: "0.5rem" }}>
                      {tradeAssetTicker}
                    </span>
                  </div>
                );
              })()
            ) : (
              <></>
            )}
          </div>
          <div
            className="flex py-2 flex-row"
            style={{ justifyContent: "right" }}
          >
            <EveButton
              typeClass="secondary"
              onClick={handleSubmit}
              disabled={
                !!state.price.error ||
                !!state.quantity.error ||
                state.isSubmittingOrder
              }
            >
              Submit
            </EveButton>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TradeWindow;
